import React from 'react';
import withLayout from '../../utils/withLayout';
import AdminLayout from '../AdminLayout';
import { Customer } from '../../types';
import {QRCodeSVG} from 'qrcode.react';

interface CustomerDetailsProps {
  customer: Customer;
}

const CustomerDetails: React.FC<CustomerDetailsProps> = ({ customer }) => {
  return (
    <section className="bg-gray-50 dark:bg-gray-900 p-3 sm:p-5 md:ml-64">
      <div className="bg-white shadow-md rounded-lg p-4">
        <h2 className="text-2xl font-bold mb-4">Datos del Cliente: {customer[0].firstName} {customer[0].lastName}</h2>
        <div className="mb-2">
          <strong>Nombre:</strong> {customer[0].firstName}
        </div>
        <div className="mb-2">
          <strong>Apellido:</strong> {customer[0].lastName}
        </div>
        <div className="mb-2">
          <strong>Email:</strong> {customer[0].email}
        </div>
        <div className="mb-2">
          <strong>Teléfono:</strong> {customer[0].phone}
        </div>
        <div className="mb-2">
          <strong>Estado:</strong> {customer[0].active ? 'Activo' : 'Inactivo'}
        </div>
        <div className="mb-2">
          <QRCodeSVG value={`customer:${customer[0].id}`} size="220" />
        </div>
      </div>
    </section>
  );
};

export default withLayout(AdminLayout)(CustomerDetails);
