import React from "react"
import { BrowserRouter as Router } from "react-router-dom"
import Hero from "../shared/Hero"
import AboutUs from "../shared/AboutUs"
import Service from "../shared/Service"
import Members from "../shared/Members"
import PricingPlanSection from "../shared/PricingPlanSection"
import AppointmentSection from "../shared/AppointmentSection"
import GallerySection from "../shared/GallerySection"
import Footer from "../shared/Footer"

const Index = (props) => {
  return (
    <React.Fragment>
      <Hero />
      <AboutUs />
      <Service />
      <Members />
      <Router>
        <PricingPlanSection />
      </Router>
      <AppointmentSection />
      <GallerySection />
      <Footer />
    </React.Fragment>
  )
}


export default Index
