import React from 'react';

const stickerIcon = require('images/sticker2.svg');
const mapImage = require('images/Map.png');
const contactIcon1 = require('images/contact-1.svg');
const contactIcon2 = require('images/contact-2.svg');
const contactIcon3 = require('images/contact-3.svg');

const AppointmentSection = () => {
  return (
    <section className="appointment py-20 lg:py-32">
      <div className="container">
        {/* Section heading */}
        <div className="section-header text-center mb-12">
          <div className="mb-4">
            <a
              href="#"
              className="primary-button inline-flex items-center gap-2 bg-rgba2 py-[5px] px-4 rounded-[5px] text-theme text-[16px] md:text-[18px] leading-[1.6em] font-normal inline-block"
            >
              <img src={stickerIcon} alt="icon" className="w-auto" />
              Appointment
            </a>
          </div>
          <h2 className="text-[26px] sm:text-[30px] lg:text-[40px] xl:text-[48px] text-dark2 font-normal uppercase leading-[1.6em]">
            Book Your Transformation
          </h2>
          <p className="text-[16px] lg:text-[20px] text-gray2 font-normal leading-[1.6em] mb-4">
            Schedule Your Grooming Experience Today
          </p>
        </div>

        {/* Form and Map Section */}
        <div className="grid lg:grid-cols-2 gap-12">
          <div className="form bg-light p-6 rounded-[12px]">
            <form action="#">
              <div className="grid lg:grid-cols-2 gap-4 mb-6">
                <div className="form-group">
                  <label
                    htmlFor="name"
                    className="capitalize block text-dark text-[16px] font-normal mb-2"
                  >
                    Your Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="input-fill w-full py-2 px-4 border border-gray rounded-[6px]"
                    placeholder="Enter Name"
                  />
                </div>
                <div className="form-group">
                  <label
                    htmlFor="Email"
                    className="capitalize block text-dark text-[16px] font-normal mb-2"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="Email"
                    className="input-fill w-full py-2 px-4 border border-gray rounded-[6px]"
                    placeholder="Enter Email"
                  />
                </div>
              </div>

              <div className="grid lg:grid-cols-2 gap-4 mb-6">
                <div className="form-group">
                  <label
                    htmlFor="Date"
                    className="capitalize block text-dark text-[16px] font-normal mb-2"
                  >
                    Date
                  </label>
                  <input
                    type="date"
                    id="Date"
                    className="input-fill w-full py-2 px-4 border border-gray rounded-[6px]"
                  />
                </div>
                <div className="form-group">
                  <label
                    htmlFor="Time"
                    className="capitalize block text-dark text-[16px] font-normal mb-2"
                  >
                    Time
                  </label>
                  <input
                    type="time"
                    id="Time"
                    className="input-fill w-full py-2 px-4 border border-gray rounded-[6px]"
                  />
                </div>
              </div>

              <div className="grid lg:grid-cols-2 gap-4 mb-8">
                <div className="form-group">
                  <label
                    htmlFor="Service"
                    className="capitalize block text-dark text-[16px] font-normal mb-2"
                  >
                    Service
                  </label>
                  <select
                    className="w-full py-3 px-4 border border-gray rounded-[6px]"
                    id="Service"
                  >
                    <option value="hair">HAIR SOLUTIONS</option>
                    <option value="spa">BEAUTY & SPA</option>
                    <option value="body">BODY TREATMENTS</option>
                    <option value="face">FACE WASHING</option>
                    <option value="meditation">MEDITATIONS</option>
                    <option value="shaving">SHAVING</option>
                  </select>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="Barber"
                    className="capitalize block text-dark text-[16px] font-normal mb-2"
                  >
                    Barber Name
                  </label>
                  <select
                    className="w-full py-3 px-4 border border-gray rounded-[6px]"
                    id="Barber"
                  >
                    <option value="volvo">HAIR SOLUTIONS</option>
                    <option value="saab">BEAUTY & SPA</option>
                    <option value="mercedes">BODY TREATMENTS</option>
                    <option value="audi">FACE WASHING</option>
                    <option value="audi">MEDITATIONS</option>
                    <option value="audi">SHAVING</option>
                  </select>
                </div>
              </div>

              <div className="btn-wrap hidden lg:block">
                <button
                  type="submit"
                  className="btn w-full bg-theme text-[15px] font-normal text-white rounded-[5px] inline-block rounded-2xl px-[30px] py-[12px] hover:bg-theme_light transition-all duration-15"
                >
                  Make Appointment
                </button>
              </div>
            </form>
          </div>
          <div className="maps">
            <img src={mapImage} alt="map" />
          </div>
        </div>

        {/* Contact Section */}
        <div className="contacts mt-12 w-4/6 mx-auto flex justify-between flex-wrap gap-4">
          <div className="flex gap-3">
            <img src={contactIcon1} alt="icon" />
            <a href="tel:084112244556">
              <span className="text-theme text-[16px] lg:text-[20px] font-normal block">Call</span>
              <span className="text-gray2 text-[14px] lg:text-[16px]">084112244556</span>
            </a>
          </div>
          <div className="flex gap-3">
            <img src={contactIcon2} alt="icon" />
            <a href="mailto:madmaninfo@gmail.com">
              <span className="text-theme text-[16px] lg:text-[20px] font-normal block">Email</span>
              <span className="text-gray2 text-[14px] lg:text-[16px]">madmaninfo@gmail.com</span>
            </a>
          </div>
          <div className="flex gap-3">
            <img src={contactIcon3} alt="icon" />
            <div>
              <span className="text-theme text-[16px] lg:text-[20px] font-normal block">Location</span>
              <span className="text-gray2 text-[14px] lg:text-[16px]">Texas, USA</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppointmentSection;
