import React, { useState, useEffect } from 'react';
import withLayout from '../../utils/withLayout';
import AdminLayout from '../AdminLayout';
import { Bundle, Service } from '../../types';
import { createBundle } from '../../api';

interface BundleFormProps {
  onSubmit: (bundle: Bundle) => void;
  services: Service[];
}

const BundleForm: React.FC<BundleFormProps> = ({ onSubmit, services }) => {
  const [bundle, setBundle] = useState({
    name: '',
    services: [],
    totalPrice: 0.00
  });
  const [serviceQuantities, setServiceQuantities] = useState({});
  const [suggestedTotalPrice, setSuggestedTotalPrice] = useState(0);

  useEffect(() => {
    const total = services.reduce((acc, service) => {
      const quantity = serviceQuantities[service.id] || 0;
      return acc + (quantity * service.price);
    }, 0);
    setSuggestedTotalPrice(total);
  }, [serviceQuantities, services]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBundle({ ...bundle, [name]: value });
  };

  const handleServiceChange = (serviceId, quantity) => {
    setServiceQuantities({ ...serviceQuantities, [serviceId]: quantity });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const selectedServices = services.filter(service => serviceQuantities[service.id] > 0)
                                     .map(service => ({
                                       serviceId: service.id,
                                       quantity: serviceQuantities[service.id]
                                     }));

    const newBundle = {
      ...bundle,
      services: selectedServices
    };

    createBundle(newBundle).then(response => {
      console.log('Bundle created successfully:', response.data);
      if (onSubmit) onSubmit(newBundle);
    }).catch(error => {
      console.error('Error creating bundle:', error);
    });
  };

  return (
    <section className="bg-white dark:bg-gray-900 border shadow-lg lg:ml-64">
      <div className="py-8 px-4 mx-auto max-w-2xl lg:py-16">
        <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">Crear Modelo Bono</h2>
        <form onSubmit={handleSubmit}>
          <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
            <div className="sm:col-span-2">
              <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Bundle Name</label>
              <input type="text" name="name" id="name" value={bundle.name} onChange={handleChange} required="" />
            </div>
            {services.map(service => (
              <div key={service.id} className="sm:col-span-2">
                <label htmlFor={`service-${service.id}`} className="block text-sm font-medium text-gray-900 dark:text-white">{service.name} - ${service.price}</label>
                <input type="number" name={`service-${service.id}`} id={`service-${service.id}`}
                       value={serviceQuantities[service.id] || 0}
                       onChange={(e) => handleServiceChange(service.id, parseInt(e.target.value))}
                       min="0" className="mt-1 block w-full p-2.5 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" />
              </div>
            ))}
            <div className="w-full">
              <label className="block text-sm font-medium text-gray-900 dark:text-white">Precio Total</label>
              <input type="number" name="totalPrice" id="totalPrice" value={bundle.totalPrice} onChange={handleChange} className="p-2.5 text-lg bg-gray-50 rounded-lg border border-gray-300" required="" />
            </div>
            <div className="w-full">
              <label className="block text-sm font-medium text-gray-900 dark:text-white">Precio Total Sugerido</label>
              <div className="p-2.5 text-lg bg-gray-50 rounded-lg border border-gray-300">${suggestedTotalPrice}</div>
            </div>
          </div>
          <button type="submit" className="mt-4 inline-flex items-center px-5 py-2.5 text-sm font-medium text-white bg-blue-700 rounded-lg">
            Crear Bono
          </button>
        </form>
      </div>
    </section>
  );
};

export default withLayout(AdminLayout)(BundleForm);
