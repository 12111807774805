import React from 'react';

const logo = require('images/logo.svg');

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-8 pt-[80px] pb-[40px]">

          {/* Widget 1 */}
          <div className="widget">
            <div className="mb-4">
              <a href="index.html">
                <img src={logo} alt="logo" />
              </a>
            </div>
            <p className="text-[14px] lg:text-[18px] text-dark leading-[28px]">
              Established with passion, our barbershop offers premium grooming services, blending tradition with contemporary styles in a welcoming environment.
            </p>
          </div>

          {/* Widget 2 */}
          <div className="widget xl:ps-28">
            <h3 className="text-dark2 text-[16px] lg:text-[20px] leading-[30px] mb-5 uppercase">Quick Link</h3>
            <div className="f-menu">
              <a href="#" className="text-[14px] lg:text-[18px] text-dark leading-[28px] block mb-4">Terms</a>
              <a href="#" className="text-[14px] lg:text-[18px] text-dark leading-[28px] block mb-4">Careers</a>
              <a href="#" className="text-[14px] lg:text-[18px] text-dark leading-[28px] block mb-4">Security</a>
              <a href="#" className="text-[14px] lg:text-[18px] text-dark leading-[28px] block mb-4">Contact Us</a>
            </div>
          </div>

          {/* Widget 3 */}
          <div className="widget xl:ps-20">
            <h3 className="text-dark2 text-[16px] lg:text-[20px] leading-[30px] mb-5 uppercase">Quick Link</h3>
            <div className="f-menu">
              <a href="#" className="text-[14px] lg:text-[18px] text-dark leading-[28px] block mb-4">About</a>
              <a href="#" className="text-[14px] lg:text-[18px] text-dark leading-[28px] block mb-4">Service</a>
              <a href="#" className="text-[14px] lg:text-[18px] text-dark leading-[28px] block mb-4">Team</a>
              <a href="#" className="text-[14px] lg:text-[18px] text-dark leading-[28px] block mb-4">Contact Us</a>
            </div>
          </div>

          {/* Widget 4 */}
          <div className="widget">
            <h3 className="text-dark2 text-[16px] lg:text-[20px] leading-[30px] mb-5 uppercase">Social</h3>
            <ul className="transition-all duration-150 mb-0">
              <li className="flex items-center gap-3 text-dark text-[14px] lg:text-[18px] mb-4">
                <a href="#" className="border border-theme inline-block p-3 rounded-md hover:bg-theme">
                  {/* SVG Icon for Gmail */}
                  <svg className="hover:stroke-white" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M24 12.2903C24 19.1613 19.2295 24 12.1967 24C5.40984 24 0 18.6774 0 12C0 5.37097 5.40984 0 12.1967 0C15.4426 0 18.2459 1.20968 20.3607 3.14516L17.0164 6.29032C12.6885 2.17742 4.62295 5.27419 4.62295 12C4.62295 16.2097 8.01639 19.5968 12.1967 19.5968C17.0164 19.5968 18.8361 16.2097 19.082 14.4194H12.1967V10.3065H23.8033C23.9016 10.9355 24 11.5161 24 12.2903Z"
                      fill="#707740"
                    />
                  </svg>
                </a>
                Gmail
              </li>
              <li className="flex items-center gap-3 text-dark text-[14px] lg:text-[18px] mb-4">
                <a href="#" className="border border-theme inline-block p-3 rounded-md hover:bg-theme">
                  {/* SVG Icon for Instagram */}
                  <svg className="hover:stroke-white" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M18.9 1.125H22.5806L14.5406 10.3376L24 22.8759H16.5943L10.7897 15.273L4.15543 22.8759H0.471429L9.07029 13.0187L0 1.12671H7.59429L12.8331 8.07471L18.9 1.125ZM17.6057 20.6679H19.6457L6.48 3.21814H4.29257L17.6057 20.6679Z"
                      fill="#707740"
                    />
                  </svg>
                </a>
                Instagram
              </li>
              <li className="flex items-center gap-3 text-dark text-[14px] lg:text-[18px] mb-4">
                <a href="#" className="border border-theme inline-block p-3 rounded-md hover:bg-theme">
                  {/* SVG Icon for Twitter */}
                  <svg className="hover:stroke-white" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12.0268 5.8125C15.4018 5.8125 18.1875 8.59821 18.1875 11.9732C18.1875 15.4018 15.4018 18.1339 12.0268 18.1339C8.59821 18.1339 5.86607 15.4018 5.86607 11.9732C5.86607 8.59821 8.59821 5.8125 12.0268 5.8125ZM12.0268 15.9911C14.2232 15.9911 15.9911 14.2232 15.9911 11.9732C15.9911 9.77679 14.2232 8.00893 12.0268 8.00893C9.77679 8.00893 8.00893 9.77679 8.00893 11.9732C8.00893 14.2232 9.83036 15.9911 12.0268 15.9911ZM19.8482 5.59821C19.8482 6.40179 19.2054 7.04464 18.4018 7.04464C17.5982 7.04464 16.9554 6.40179 16.9554 5.59821C16.9554 4.79464 17.5982 4.15179 18.4018 4.15179C19.2054 4.15179 19.8482 4.79464 19.8482 5.59821ZM23.9196 7.04464C24.0268 9.02679 24.0268 14.9732 23.9196 16.9554C23.8125 18.8839 23.3839 20.5446 21.9911 21.9911C20.5982 23.3839 18.8839 23.8125 16.9554 23.9196C14.9732 24.0268 9.02679 24.0268 7.04464 23.9196C5.11607 23.8125 3.45536 23.3839 2.00893 21.9911C0.616071 20.5446 0.1875 18.8839 0.0803571 16.9554C-0.0267857 14.9732 -0.0267857 9.02679 0.0803571 7.04464C0.1875 5.11607 0.616071 3.40179 2.00893 2.00893C3.45536 0.616071 5.11607 0.1875 7.04464 0.0803571C9.02679 -0.0267857 14.9732 -0.0267857 16.9554 0.0803571C18.8839 0.1875 20.5982 0.616071 21.9911 2.00893C23.3839 3.40179 23.8125 5.11607 23.9196 7.04464Z"
                      fill="#707740"
                    />
                  </svg>
                </a>
                Twitter
              </li>
            </ul>
          </div>

        </div>
        <div className="copyright text-center bg-green_dark2 py-4">
          <p className="text-white">
            Copyright © 2024 Mad Men . All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
