import React, { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook'
import { Location } from '../../types';
import { push } from '../../utils/push';
import { updateCurrentLocation } from '../../api';
import SearchModal from './SearchModal';

interface HeaderProps {
  currentLocation: string;
  locations: string[];
  onLocationChange: (location: Location) => void;
}

const handleLocationChange = (location) => {
  updateCurrentLocation(location.id)
    .then((response) => {
      console.log('Location updated successfully:', response.data);
      onLocationChange(location);
      window.location.reload();
    })
    .catch((error) => {
      console.error('Error updating location:', error);
    });
};

const onLocationChange = (location: Location) => {
  console.log('Location changed:', location);
}

const Header: React.FC<HeaderProps> = ({ currentLocation, locations }) => {
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  useHotkeys('ctrl+k', () => toggleSearchModal())

  const toggleSearchModal = () => {
    setIsSearchModalOpen(!isSearchModalOpen);
  };

  let locationName = '';
  if(currentLocation) {
    locationName = Array.isArray(currentLocation) ? currentLocation[0].name : currentLocation.name;
  }

  return (
    <header className="antialiased">
      <nav className="bg-white border-gray-200 px-4 lg:px-6 py-4 ">
        <div className="flex flex-wrap justify-between items-center">
          <div className="flex justify-start items-center">
            {/* Sidebar toggle buttons and logo go here */}
          </div>
          <div className="flex items-center lg:order-2">
            <button id="searchModalButton" onClick={toggleSearchModal} className="mx-5 flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg md:w-auto focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0 1 3.75 9.375v-4.5ZM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 0 1-1.125-1.125v-4.5ZM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0 1 13.5 9.375v-4.5Z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 6.75h.75v.75h-.75v-.75ZM6.75 16.5h.75v.75h-.75v-.75ZM16.5 6.75h.75v.75h-.75v-.75ZM13.5 13.5h.75v.75h-.75v-.75ZM13.5 19.5h.75v.75h-.75v-.75ZM19.5 13.5h.75v.75h-.75v-.75ZM19.5 19.5h.75v.75h-.75v-.75ZM16.5 16.5h.75v.75h-.75v-.75Z" />
              </svg>
            </button>
            <SearchModal isOpen={isSearchModalOpen} onClose={toggleSearchModal} />
            <button id="locationsDropdownButton" data-dropdown-toggle="locationsDropdown" className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg md:w-auto focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
              <svg className="-ml-1 mr-1.5 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path clipRule="evenodd" fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
              </svg>
              {currentLocation ? locationName : "Sedes"}
            </button>
            <div id="locationsDropdown" className="z-10 hidden bg-white divide-y divide-gray-100 rounded shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
              {locations.length > 0 && (
                <ul className="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="locationsDropdownButton">
                  {locations.map((location) => (
                    <li key={location.id}>
                      <a onClick={() => handleLocationChange(location)} className="cursor-pointer block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">{location.name}</a>
                    </li>
                  ))}
                </ul>
              )}
              <div className="py-1">
                <a onClick={() => push("/admin/locations/new")} className="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Crear Nueva Sede</a>
              </div>
            </div>
          </div>
          <div className="flex items-center lg:order-2">
            {/* Notifications button */}
            <button type="button" className="p-2 mr-1 bg-primary-100 text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100 ">
              {/* Bell icon */}
              {/* Dropdown menu */}
            </button>
            {/* Apps button */}
            <button type="button" className="p-2 bg-primary-100 text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100 ">
              {/* Icon */}
              {/* Dropdown menu */}
            </button>
            {/* User profile button */}
            <button type="button" className="flex mx-3 text-sm bg-gray-800 rounded-full md:mr-0 ">
              <span className="sr-only">Open user menu</span>
              <img className="w-8 h-8 rounded-full" src="https://flowbite.com/docs/images/people/profile-picture-5.jpg" alt="user photo" />
            </button>
            {/* Dropdown menu */}
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
