import React, { useState } from 'react';
import Modal from 'react-modal';
import { searchCustomers } from '../../api';
import { push } from '../../utils/push';

const SearchModal = ({ isOpen, onClose }) => {
  const handleSubmit = async (e) => {
    e.preventDefault();
    const searchCode = e.target.searchCode.value;
    const [type, uuid] = searchCode.split(':');

    if (type === 'customer') {
      try {
        const response = await searchCustomers(uuid);
        if (response.data.success) {
          push(`/admin/customers/${response.data.customer.id}`); // Adjust the path as needed
          onClose(); // Close the modal
        } else {
          console.error('Customer not found');
        }
      } catch (error) {
        console.error('Error searching customer:', error);
      }
    } else if (type === 'order') {
      // Handle order search
    } else {
      console.error('Invalid search code');
    }
  };


  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Search Modal"
      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white w-96 p-4 rounded-lg shadow-lg"
      overlayClassName="fixed inset-0"
    >
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold">Escanea el QR ahora...</h2>
        <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <form onSubmit={handleSubmit} className="flex space-x-2">
        <input
          type="text"
          id="searchCode"
          name="searchCode"
          className="flex-1 p-2 border border-gray-300 rounded-lg"
          autoFocus
        />
      </form>
    </Modal>
  );
};

export default SearchModal;