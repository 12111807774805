// Build full path using provided relative path
function buildPath(path: string): string {
  return window.location.origin + path;
}

// Imitates the behavior of React Router's push() in vanilla JS
export function push(path: string): void {
  window.location.assign(buildPath(path));
}

export function goBack(): void {
  window.history.back();
}
