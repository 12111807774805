import React from 'react';

const MembersSection = () => {
  const memberBg = require('images/membar-bg.png');
  const member2 = require('images/member2.png');
  const member3 = require('images/member3.png');
  const svg1 = require('images/st3.svg');
  const aboutImg = require('images/about-img.png');
  const member1 = require('images/member1.png');
  return (
    <section className="members py-[80px] lg:py-[120px]" style={{ backgroundImage: `url(${memberBg})` }}>
      <div className="container">
        <div className="section-header text-center mb-12">
          <div className="mb-4">
            <a
              href="#"
              className="primary-button inline-flex items-center gap-2 bg-rgba3 py-[5px] px-4 rounded-[5px] text-white text-[16px] md:text-[18px] leading-[1.6em] font-normal inline-block"
            >
              <img src={svg1} alt="icon" className="w-auto" />
              Members
            </a>
          </div>
          <h2 className="text-[26px] sm:text-[30px] lg:text-[40px] xl:text-[48px] text-white font-normal uppercase leading-[1.6em]">
            We've Lots of Experienced Members Barbers
          </h2>
          <h3 className="text-[16px] lg:text-[20px] text-white font-normal leading-[1.6em] mb-4">
            Join our community for exclusive discounts, VIP treatment, and personalized grooming services tailored just for you.
          </h3>
        </div>
        <div className="members-grid grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <div className="single-member p-6 bg-white hover:bg-green_dark rounded-[12px] group text-center transition-all duration-150">
            <div className="member-img mb-6">
              <img src={member1} className="mx-auto" alt="member img" />
            </div>
            <h4 className="text-[24px] leading-[34px] text-dark group-hover:text-white uppercase mb-4">
              Mitchell
            </h4>
            <h5 className="text-[20px] lg:text-[24px] leading-[34px] text-red_light mb-4">
              Senior Barber
            </h5>
            <ul className="flex gap-3 items-center justify-center transition-all duration-150 mb-0">
              {/* Add social icons here */}
            </ul>
          </div>
          <div className="single-member p-6 bg-white hover:bg-green_dark rounded-[12px] group text-center transition-all duration-150">
            <div className="member-img mb-6">
              <img src={member2} className="mx-auto" alt="member img" />
            </div>
            <h4 className="text-[24px] leading-[34px] text-dark group-hover:text-white uppercase mb-4">
              Esther
            </h4>
            <h5 className="text-[20px] lg:text-[24px] leading-[34px] text-red_light mb-4">
              Senior Barber
            </h5>
            <ul className="flex gap-3 items-center justify-center transition-all duration-150 mb-0">
              {/* Add social icons here */}
            </ul>
          </div>
          <div className="single-member p-6 bg-white hover:bg-green_dark rounded-[12px] group text-center transition-all duration-150">
            <div className="member-img mb-6">
              <img src={member3} className="mx-auto" alt="member img" />
            </div>
            <h4 className="text-[24px] leading-[34px] text-dark group-hover:text-white uppercase mb-4">
              Philip
            </h4>
            <h5 className="text-[20px] lg:text-[24px] leading-[34px] text-red_light mb-4">
              Senior Barber
            </h5>
            <ul className="flex gap-3 items-center justify-center transition-all duration-150 mb-0">
              {/* Add social icons here */}
            </ul>
          </div>
          <div className="single-member p-6 bg-white hover:bg-green_dark rounded-[12px] group text-center transition-all duration-150">
            <div className="member-img mb-6">
              <img src={member1} className="mx-auto" alt="member img" />
            </div>
            <h4 className="text-[24px] leading-[34px] text-dark group-hover:text-white uppercase mb-4">
              Shane
            </h4>
            <h5 className="text-[20px] lg:text-[24px] leading-[34px] text-red_light mb-4">
              Senior Barber
            </h5>
            <ul className="flex gap-3 items-center justify-center transition-all duration-150 mb-0">
              {/* Add social icons here */}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MembersSection;
