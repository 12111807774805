import React from "react";

interface HeaderProps {
  // Add any props here
}

const Header: React.FC<HeaderProps> = (props) => {
  const headerTileClass = "block py-2 pr-4 pl-3 text-secondary-200 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700";

  const logoSrc = require('images/logo.png');

  return (
    <React.Fragment>
      <header className="py-[16px] sticky top-0 z-[99999] w-full bg-white">
        <div className="container">
          <div className="flex justify-between items-center">
            <div>
              <div className="logo-wrap">
                <a href="index.html">
                  <img src={logoSrc} alt="logo" className="" />
                </a>
              </div>
            </div>

            <div>
              <nav className="hidden lg:flex gap-10 items-center justify-center">
                <a href="index.html"
                  className="text-[16px] font-medium leading-[1.6em] text-dark hover:text-theme transition-all duration-150">
                  Home
                </a>
                <a href="#"
                  className="text-[16px] font-medium leading-[1.6em] text-dark hover:text-theme transition-all duration-150">
                  About
                </a>
                <a href="#"
                  className="text-[16px] font-medium leading-[1.6em] text-dark hover:text-theme transition-all duration-150">
                  Service
                </a>
                <a href="#"
                  className="text-[16px] font-medium leading-[1.6em] text-dark hover:text-theme transition-all duration-150">
                  Team
                </a>
                <a href="#"
                  className="text-[16px] font-medium leading-[1.6em] text-dark hover:text-theme transition-all duration-150">
                  Contact Us
                </a>
              </nav>
            </div>

            <div className="btn-wrap hidden lg:block">
              <a href="#"
                className="btn bg-theme text-[15px] font-normal text-white rounded-[5px] inline-block rounded-2xl px-[30px] py-[12px] hover:bg-theme_light transition-all duration-15">
                Book An Appointment
              </a>
            </div>

            <button id="toggleBtn" className="block lg:hidden">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_284_30059)">
                  <path d="M24 11.0006H0V13.0006H24V11.0006Z" fill="#374957"></path>
                  <path d="M24 4.00024H0V6.00024H24V4.00024Z" fill="#374957"></path>
                  <path d="M24 18H0V20H24V18Z" fill="#374957"></path>
                </g>
                <defs>
                  <clipPath id="clip0_284_30059">
                    <rect width="24" height="24" fill="white"></rect>
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
