import React from 'react';

const AboutUs: React.FC = () => {
  const aboutImg = require('images/about-img.png');
  const sticker2 = require('images/sticker2.svg');

  return (
    <section className="about-us py-[80px] lg:py-[120px]">
      <div className="container">
        <div className="grid lg:grid-cols-2 gap-4">
          <div className="sec-img">
            <img src={aboutImg} alt="about-img" />
          </div>
          <div className="section-header">
            <div className="mb-4">
              <a href="#"
                className="primary-button inline-flex items-center gap-2 bg-rgba2 py-[5px] px-4 rounded-[5px] text-theme text-[16px] md:text-[18px] leading-[1.6em] font-normal inline-block">
                <img src={sticker2} alt="icon" className="w-auto" />
                About Us
              </a>
            </div>
            <h2 className="text-[26px] sm:text-[30px] lg:text-[40px] xl:text-[48px] text-dark2 font-normal uppercase leading-[1.6em]">
              About Our Mad Man Story
            </h2>
            <h3 className="text-[16px] xl:text-[20px] text-dark font-normal leading-[1.6em] mb-4">
              Where Every Cut Tells a Story
            </h3>
            <p className="text-[14px] md:text-[18px] text-gray2 font-normal leading-[1.6em] mb-10">
              Welcome to Mad man, where every haircut is a chapter in our Mad Man story. Inspired by the
              sophistication and style of the Mad Men era, our barbershop is a tribute to timeless elegance
              and razor-sharp precision. Step into our vintage-inspired space and journey back to a time when
              grooming was an art form and every detail mattered.
            </p>
            <div className="readmore">
              <a href="#" className="px-[35px] py-[15px] text-theme border border-theme rounded-[5px]">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;