import React from 'react';
import { push } from '../utils/push';
import withLayout from '../utils/withLayout';
import AdminLayout from './AdminLayout';

interface DashboardProps {
  locationsCount: number;
}

const Dashboard: React.FC<DashboardProps> = ({ locationsCount }) => {
  return (
    <div>
      {locationsCount < 1 && (
        <div className="text-center">
          <p className="text-center mt-8">Para empezar crea tu primera sede.</p>
          <button onClick={() => push("/admin/locations/new")} className="bg-primary-200 text-secondary-100 px-4 py-2 rounded-lg mt-4 cursor-pointer">Crear sede</button>
        </div>
      )}
    </div>
  );
};

export default withLayout(AdminLayout)(Dashboard);