import React from 'react';
import { Link } from 'react-router-dom';

const stickerIcon = require('images/sticker2.svg');
const plan1Icon = require('images/plan1.svg');
const plan2Icon = require('images/plan2.svg');
const dottedLine = require('images/dotted.svg');
const planBg = require('images/plan-bg.png');

const PricingSection = () => {
  const pricingPlans = [
    { id: "Hair_Solutions", name: "Hair Solutions", icon: plan1Icon, services: [{ name: "Hair Cut", price: 10 }, { name: "Hair Styling", price: 15 }, { name: "Hair Trimming", price: 20 }] },
    { id: "Beauty_Spa", name: "Beauty & Spa", icon: plan2Icon, services: [{ name: "Beauty Spa", price: 10 }, { name: "Spa Styling", price: 15 }, { name: "Spa Trimming", price: 20 }] },
    // Add more plans as needed
  ];

  return (
    <section className="py-20 lg:py-32" style={{ backgroundImage: planBg }}>
      <div className="container mx-auto">
        <div className="section-header text-center mb-12">
          <div className="mb-4">
            <Link to="#" className="inline-flex items-center gap-2 bg-blue-500 py-1 px-4 rounded text-white text-lg">
              <img src={stickerIcon} alt="icon" className="w-auto" />
              Pricing Plan
            </Link>
          </div>
          <h2 className="text-3xl lg:text-4xl font-light uppercase">
            Awesome Pricing Plan
          </h2>
          <p className="text-lg text-gray-600">
            Our transparent pricing ensures affordability without compromising quality.
          </p>
        </div>
        <div className="tab-wrapper">
          <div className="w-full">
            <ul className="tabs flex gap-2 flex-wrap justify-center">
              {pricingPlans.map(plan => (
                <li key={plan.id} className="tab text-center py-3 px-5 inline-block bg-white hover:bg-blue-500 text-blue-500 hover:text-white rounded-lg transition duration-300">
                  <img src={plan.icon} alt={plan.name} className="mb-3 w-auto" />
                  {plan.name}
                </li>
              ))}
            </ul>
          </div>
          <div className="tab-content mt-10 md:mt-16">
            {pricingPlans.map(plan => (
              <div key={plan.id} className="w-full">
                <div className="tab-inner grid lg:grid-cols-2 gap-8">
                  {plan.services.map(service => (
                    <div key={service.name} className="pricing mb-4">
                      <div className="price flex justify-between items-center mb-2">
                        <h5 className="text-xl font-light uppercase">
                          {service.name}
                        </h5>
                        <img src={dottedLine} alt="line" className="w-full" />
                        <h5 className="text-xl font-light uppercase">
                          ${service.price}
                        </h5>
                      </div>
                      <p className="text-red-300">
                        Clean & simple 30-40 minutes
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingSection;
