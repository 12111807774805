import React from 'react';

interface FlashMessageProps {
  title: string;
  message: string | string[];
  type: 'success' | 'error' | 'warning' | 'info';
}

const FlashMessage: React.FC<FlashMessageProps> = ({ title, message, type }) => {
  const bgColor = {
    success: 'bg-green-100 border-green-500 text-green-700',
    error: 'bg-red-100 border-red-500 text-red-700',
    warning: 'bg-orange-100 border-orange-500 text-orange-700',
    info: 'bg-blue-100 border-blue-500 text-blue-700',
  };

  return (
    <div className={`${bgColor[type]} border-l-4 p-4`} role="alert">
      <p className="font-bold">{title}</p>
      {Array.isArray(message) ? (
        message.map((msg, index) => <p key={index}>{msg}</p>)
      ) : (
        <p>{message}</p>
      )}
    </div>
  );
};

export default FlashMessage;
