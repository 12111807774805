import React from 'react';

const galleryImages = [
  require('images/gallery1.png'),
  require('images/gallery2.png'),
  require('images/gallery3.png'),
  require('images/gallery4.png'),
  require('images/gallery5.png'),
  require('images/gallery6.png'),
  require('images/gallery7.png'),
  require('images/gallery8.png'),
  require('images/gallery9.png'),
  require('images/gallery10.png'),
];

const instaIcon = require('images/insta-Icon.svg');

const GallerySection = () => {
  return (
    <section className="gallery-container">
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
        {galleryImages.map((image, index) => (
          <div key={index} className="gallery-item relative">
            <img src={image} alt={`gallery image ${index + 1}`} />
            <a
              href="#"
              className="after-hover absolute bottom-0 left-0 top-0 right-0 flex min-h-full items-center justify-center bg-rgba4"
            >
              <img src={instaIcon} alt="Instagram icon" />
            </a>
          </div>
        ))}
      </div>
    </section>
  );
};

export default GallerySection;
