import React from 'react';

const Hero: React.FC = () => {
  const bannerBg = require('images/banner-bg.png');
  const sticker = require('images/sticker.svg');

  return (
    <section className="bg-no-repeat bg-cover bg-bottom" style={{ backgroundImage: `url(${bannerBg})` }}>
      <div className="container">
        <div className="banner-slider owl-carousel owl-theme">
          <div className="s-item min-h-[600px] lg:min-h-[785px] flex items-center">
            <div>
              <div className="mb-6">
                <a href="#"
                  className="primary-button inline-flex items-center gap-2 bg-rgba py-[5px] px-4 rounded-[5px] text-white text-[16px] md:text-[18px] leading-[1.6em] font-normal inline-block">
                  <img src={sticker} alt="icon" className="w-auto" />
                  Welcome to <b>Our Mad Man</b>
                </a>
              </div>
              <h1 className="text-[28px] lg:text-[38px] xl:text-[54px] text-white font-normal uppercase mb-7 leading-[1.3em] mt-7">
                Precision Cuts & Classic Styles
              </h1>
              <p className="text-sm lg:text-[20px] text-gray tracking-tight leading-[1.46em] mb-8">
                Expertly Crafted Haircuts for Timeless Sophistication. Experience the <br className="hidden lg:inline-block" /> Art of Barbering with
                Precision Techniques and Classic Elegance
              </p>
              <div className="btn-wrap hidden lg:block">
                <a href="#"
                  className="btn bg-theme text-[15px] font-normal text-white rounded-[5px] inline-block rounded-2xl px-[30px] py-[12px] hover:bg-theme_light transition-all duration-15">
                  Book An Appointment
                </a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default Hero;
