import React from 'react';
import withLayout from '../../utils/withLayout';
import AdminLayout from '../AdminLayout';
import { Location, Barber } from '../../types';
import { inviteBarber } from '../../api';
import { push } from '../../utils/push';
import { fakerES as faker } from '@faker-js/faker';

interface BarberFormProps {
  barber: Barber;
  currentLocation: Location;
  allLocations: Location[];
}

const BarberForm: React.FC<BarberFormProps> = ({ onSubmit, currentLocation, allLocations }) => {
  const firstName = faker.person.firstName();
  const lastName = faker.person.lastName();

  const [barber, setBarber] = React.useState<Barber>({
    dni: "12345678",
    email: `${firstName.split(' ')[0].toLowerCase()}.${lastName.split(' ')[0].toLowerCase()}@gmail.com`,
    firstName: firstName,
    lastName: lastName,
    phone: '3024888888',
    assignedToLocationId: currentLocation[0].id,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBarber({ ...barber, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
     inviteBarber(barber)
      .then((response) => {
        if(response.data.success === true){
          console.log('Barber invited successfully:', response.data);
          push('/admin/barbers');
        }
      })
      .catch((error) => {
        console.error('Error inviting barber:', error);
        // Handle error (e.g., show an error message)
      });
  };

  const inputClass: String = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"

  return (
    <form onSubmit={handleSubmit} className="bg-white dark:bg-gray-900 border shadow-lg">
      <div className="py-8 px-4 mx-auto max-w-2xl lg:py-16">
        <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">Invitar Barbero</h2>
        <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
          <div className="w-full">
            <label htmlFor="dni" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">DNI</label>
            <input type="text" name="dni" id="dni" value={barber.dni} onChange={handleChange} className={inputClass} required="" />
          </div>
          <div className="w-full">
            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
            <input type="email" name="email" id="email" value={barber.email} onChange={handleChange} className={inputClass} required="" />
          </div>
          <div className="w-full">
            <label htmlFor="firstName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nombre</label>
            <input type="text" name="firstName" id="firstName" value={barber.firstName} onChange={handleChange} className={inputClass} required="" />
          </div>
          <div className="w-full">
            <label htmlFor="lastName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Apellido</label>
            <input type="text" name="lastName" id="lastName" value={barber.lastName} onChange={handleChange} className={inputClass} required="" />
          </div>
          <div className="w-full">
            <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Teléfono</label>
            <input type="text" name="phone" id="phone" value={barber.phone} onChange={handleChange} className={inputClass} required="" />
          </div>
          <div className="w-full">
            <label htmlFor="assignedToLocationId" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Sede Asignada</label>
            <select name="assignedToLocationId" id="assignedToLocationId" value={barber.assignedToLocationId} onChange={handleChange} className={inputClass} required="">
              <option value="">Seleccione una sede</option>
              {allLocations.map((location) => (
                <option key={location.id} value={location.id}>{location.name}</option>
              ))}
            </select>
          </div>
        </div>
        <button type="submit" className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-secondary-100 bg-primary-200 rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-100">
          Invitar
        </button>
      </div>
    </form>
  );
};

export default withLayout(AdminLayout)(BarberForm);
