import React, { useState } from 'react';
import withLayout from '../../utils/withLayout';
import AdminLayout from '../AdminLayout';
import { Location } from '../../types';
import { createLocation } from '../../api';
import { push } from '../../utils/push';
import FlashMessage from '../../shared/FlashMessage';
// import { useCountries } from 'use-react-countries';

interface Country {
  name: string;
}

const LocationForm: React.FC<Location> = ({ onSubmit }) => {
  const [location, setLocation] = useState({
    name: 'Nuevos Ministerios',
    addressLineOne: 'asdasdsa',
    addressLineTwo: 'asdsadasd',
    city: 'Madrid',
    province: 'Madrid',
    postal: '12345',
    country: 'España',
  });

  const [flashMessage, setFlashMessage] = useState(null);

  // const { countries } = useCountries();

  const countries: Country[] = [{ name: 'España' }];

  const inputClass: String = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
  const postalCodeClass: String = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border dark:focus:border-primary-500"

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocation({ ...location, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    createLocation(location) // Call the createLocation function with the location state
      .then((response) => {
        if(response.data.success === true){
          console.log('Location created successfully:', response.data);
          push('/admin/locations');
        }
      })
      .catch((error) => {
        setFlashMessage({ message: error.response.data.errors, type: 'error', title: 'No se pudo crear la sede. Por favor, revisa los errores e intenta de nuevo.'});
        console.error('Error creating location:');
        // Handle error (e.g., show an error message)
      });
  };

  return (
    <section className="bg-white dark:bg-gray-900 border shadow-lg lg:ml-64">
      {flashMessage && (
        <FlashMessage message={flashMessage.message} title={flashMessage.title} type={flashMessage.type} />
      )}
      <div className="py-8 px-4 mx-auto max-w-2xl lg:py-16">
        <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">Agregar Nueva Sede</h2>
        <form onSubmit={handleSubmit}>
          <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
            <div className="sm:col-span-2">
              <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nombre de la Sede</label>
              <input type="text" name="name" id="name" value={location.name} onChange={handleChange} className={inputClass} placeholder="Nuevos Ministerios" required="" />
            </div>
            <div className="w-full">
              <label htmlFor="addressLineOne" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Dirección</label>
              <input type="text" name="addressLineOne" id="addressLineOne" value={location.addressLineOne} onChange={handleChange} className={inputClass} placeholder="C/ Santa Maria 45" required="" />
            </div>
            <div className="w-full">
              <label htmlFor="addressLineTwo" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Complemento Dirección</label>
              <input type="text" name="addressLineTwo" id="addressLineTwo" value={location.addressLineTwo} onChange={handleChange} className={inputClass} placeholder="Apt. 5B" />
            </div>
            <div className="w-full">
              <label htmlFor="city" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Ciudad</label>
              <input type="text" name="city" id="city" value={location.city} onChange={handleChange} className={inputClass} placeholder="Madrid" required="" />
            </div>
            <div className="w-full">
              <label htmlFor="province" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Provincia</label>
              <input type="text" name="province" id="province" value={location.province} onChange={handleChange} className={inputClass} placeholder="Madrid" required="" />
            </div>
            <div className="w-full">
              <label htmlFor="postal" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Codigo Postal</label>
              <input type="text" name="postal" id="postal" value={location.postal} onChange={handleChange} className={postalCodeClass} placeholder="28012" required="" />
            </div>
            <div className="w-full">
              <label htmlFor="country" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">País</label>
              <select
                id="country"
                name="country"
                value={location.country}
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              >
                <option value="">Selecciona País</option>
                {countries.map((country) => (
                  <option key={country.name} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <button type="submit" className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-secondary-100 bg-primary-200 rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-100">
              Crear Sede
          </button>
        </form>
      </div>
    </section>
  );
};

export default withLayout(AdminLayout)(LocationForm);
