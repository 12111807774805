import React from 'react';
import { wrapDisplayName } from 'recompose';

// HOC that wraps a component in a layout, passing all props down to it
function withLayout<P>(LayoutComponent: React.ElementType) {
  return (Wrapped: React.ComponentType<P>) => {
    const LayoutWrapper: React.FC<P> = (props) => {
      return (
        <LayoutComponent {...props}>
          <Wrapped {...props} />
        </LayoutComponent>
      );
    };
    LayoutWrapper.displayName = wrapDisplayName(Wrapped, 'withLayout');
    return LayoutWrapper;
  };
}

export default withLayout;
