import React from 'react';
import withLayout from '../../utils/withLayout';
import AdminLayout from '../AdminLayout';
import { Bundle } from '../../types'; // Ensure this type is defined according to your bundle attributes

interface BundlesProps {
  bundles: Bundle[];
}

const Bundles: React.FC<BundlesProps> = ({ bundles }) => {
  return (
    <section className="bg-gray-50 p-5">
      <h1 className="text-2xl font-bold text-center mb-5">Bonos Creados</h1>
      <div className="grid grid-cols-3 gap-4">
        {bundles.map((bundle) => (
          <div key={bundle.id} className="bg-white p-4 shadow rounded-lg">
            <h2 className="text-lg font-semibold">{bundle.name}</h2>
            <p className="text-md">Price: ${bundle.total_price.toFixed(2)}</p>
            <ul className="list-disc pl-5">
              {bundle.services.map(service => (
                <li key={service.id}>{service.name}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
  );
};

export default withLayout(AdminLayout)(Bundles);
