import React, { ReactNode, useState } from 'react';
import Sidebar from './shared/Sidebar';
import Header from './shared/Header';
import { Location } from '../types';

interface AdminLayoutProps {
  children: ReactNode;
  isUserAdmin: boolean;
  locationsCount: number;
  currentLocation: Location;
  locations: Location[];
}

const AdminLayout: React.FC<AdminLayoutProps> = ({ children, isUserAdmin, locationsCount, currentLocation, locations }) => {
  return (
    <div className="flex flex-col h-screen">
      <Header currentLocation={currentLocation} locations={locations} />
      <div className="md:flex md:flex-1">
        <Sidebar isUserAdmin={isUserAdmin} locationsCount={locationsCount} />
        {/* Main content */}
        <div className="flex-1 px-2">
          {children}
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
