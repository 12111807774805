import axios, { InternalAxiosRequestConfig } from 'axios';
import { Location, Barber, Service } from './types';

// Set up a base URL if you have a different endpoint for your API, otherwise, it will default to the current domain
const baseURL = '/';

const api = axios.create({
  baseURL: baseURL,
  // Other custom settings
});

// Automatically attach CSRF token to the request headers
api.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const token = (document.querySelector("[name='csrf-token']") as HTMLMetaElement).content;
  config.headers['X-CSRF-TOKEN'] = token;
  return config;
});

export const createLocation = (locationData: Location) => {
  return api.post('/admin/locations', { location: locationData });
};

export const inviteBarber = (barberData: Barber) => {
  return api.post('/admin/barbers', { barber: barberData });
};

export const updateCurrentLocation = (locationId: number) => {
  return api.patch(`/admin/set_current_location/${locationId}`);
}

// Services

export const createService = (service: Service) => {
  return api.post('/admin/services', { service: service });
};

// Search

export const searchCustomers = (query: string) => {
  return api.get(`/admin/customers/search?query=${query}`);
}

// Bundles

export const createBundle = (bundleData: any) => {
  return api.post('/admin/bundles', { bundle: bundleData });
};

export default api;
