import React, { useState } from 'react';
import withLayout from '../../utils/withLayout';
import AdminLayout from '../AdminLayout';
import { Location, Service } from '../../types';
import { createService } from '../../api';
import { push } from '../../utils/push';

interface ServiceFormProps {
  currentLocation: Location;
  allLocations: Location[];
}

const ServiceForm: React.FC<ServiceFormProps> = ({ currentLocation, allLocations }) => {
  console.log('currentLocation', currentLocation);
  const [service, setService] = useState<Service>({
    name: 'Corte',
    price: '40',
    duration: '30',
    serviceType: '',
    locationId: currentLocation[0].id,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setService({ ...service, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    createService(service)
      .then((response) => {
        console.log('Service created successfully:', response.data);
        push('/admin/services');
      })
      .catch((error) => {
        console.error('Error creating service:', error);
        // Handle error (e.g., show an error message)
      });
  };

  const inputClass = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500";

  return (
    <form onSubmit={handleSubmit} className="bg-white dark:bg-gray-900 border shadow-lg">
      <div className="py-8 px-4 mx-auto max-w-2xl lg:py-16">
        <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">Agregar Servicio</h2>
        <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
          <div className="w-full">
            <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nombre del Servicio</label>
            <input type="text" name="name" id="name" value={service.name} onChange={handleChange} className={inputClass} required="" />
          </div>
          <div className="w-full">
            <label htmlFor="price" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Precio</label>
            <input type="text" name="price" id="price" value={service.price} onChange={handleChange} className={inputClass} required="" />
          </div>
          <div className="w-full">
            <label htmlFor="duration" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Duración (minutos)</label>
            <input type="text" name="duration" id="duration" value={service.duration} onChange={handleChange} className={inputClass} required="" />
          </div>
          <div className="w-full">
            <label htmlFor="serviceType" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Tipo de Servicio</label>
            <select name="serviceType" id="serviceType" value={service.serviceType} onChange={handleChange} className={inputClass} required="">
              <option value="">Seleccione un tipo</option>
              <option value="haircut">Corte</option>
              <option value="beard">Barba</option>
              <option value="trim">Retoque</option>
            </select>
          </div>
          <div className="w-full">
            <label htmlFor="locationId" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Sede</label>
            <select name="locationId" id="locationId" value={service.locationId} onChange={handleChange} className={inputClass} required="">
              {allLocations.map((location) => (
                <option key={location.id} value={location.id}>{location.name}</option>
              ))}
            </select>
          </div>
        </div>
        <button type="submit" className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-secondary-100 bg-primary-200 rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-100">
          Agregar
        </button>
      </div>
    </form>
  );
};

export default withLayout(AdminLayout)(ServiceForm);
